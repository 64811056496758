#app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}


.header nav {
    padding: 0 20px;
}

.content {
    padding: 20px;
    min-height: 100vh;
}

.footer {
    padding: 20px;
}

.home-card a {
	color: white;
}

.home-card a:hover {
	text-decoration: none;
}

.red {
    color: #f44336;
}
.green {
    color: #429321;
}
.price-sup {
    margin-left: 3px;
}




